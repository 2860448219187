import { BulkEvaluation } from './../model/PaymentRequestForm';
import { PaymentRequestService } from './../services/paymentRequestService';
import { PaymentRequestItem } from './../model/PaymentRequestItem';

import { Component, OnInit, ViewChild } from '@angular/core';
import { Page} from '../model/page';
import { CsvModule } from '@ctrl/ngx-csv';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-paymentrequests',
   templateUrl: './paymentrequests.component.html',
  styleUrls: ['./paymentrequests.component.css']
})
export class PaymentRequestsComponent implements OnInit {
  page = new Page();
  selected = [];
  message = '';
  range = "0";
  expanded: any = {};
  @ViewChild('myTable') table: any;
  status = null;
  newstatus = 0;
  from  =  null;
  to = null;
  rows = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  email = null;
  loading: any = true;
  constructor(private paymentRequestService: PaymentRequestService, private toastr: ToastrService) {
    this.page.pageNumber = 0;
    this.page.size = 100;
  }

  ngOnInit() {
       this.setPage({ offset: 0, size:100 });

  } // end on init
  filter() {
    this.paymentRequestService.getResult(this.page, this.email, (this.status == -1? null: this.status),
                                           this.from, this.to).then(pagedData => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      this.loadingIndicator = false;
    });
  }
  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }
  getStatus(status){
    if(status===0) return '<span class="badge badge-warning">In Progress</span>';
    if(status===1) return '<span class="badge badge-success">Approved</span>';
    if(status===2) return '<span class="badge badge-danger">Rejected</span>';
    if(status===3) return '<span class="badge badge-primary">Completed</span>';

    return '<span class="badge badge-info">-</span>';
  }
  setDateRange() {
    let dt = new Date();
    let month = dt.getMonth(), year = dt.getFullYear();
    if(this.range === "0" ) this.from = new Date(year, month, 1);
    if(this.range === "0" ) this.to = new Date(year, month + 1, 0);
    if(this.range === "1" ) this.from = new Date(year, month -1, 1);
    if(this.range === "1" ) this.to = new Date(year, month + 1, 0);
    if(this.range === "2" ) this.from = new Date(year, month -3, 1);
    if(this.range === "2" ) this.to = new Date(year, month + 1, 0);
    if(this.range === "3" ) this.from = new Date(year, 1, 1);
    if(this.range === "3" ) this.to = new Date(year + 1,1, 0);
    this.filter();
  }
  postForm() {
    let ids = [];
    this.selected.map(s => ids.push(s.id));
    let form = new BulkEvaluation(ids, this.newstatus, this.message, 1000, null);
    this.paymentRequestService.savePaymentRequestForm(form).then((d)=>{
      this.toastr.success('Operation Completed Successfully!');
      this.filter();
    });

  }
  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event) {
    console.log('Activate Event', event);
  }
  setPage(pageInfo) {
    console.log(pageInfo );
    this.page.size = pageInfo.size;
    this.page.pageNumber = pageInfo.offset;
    this.paymentRequestService.getResult(this.page, this.email,  (this.status == -1? null: this.status),
            this.from, this.to).then(pagedData => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      this.loadingIndicator = false;
    });
  }
}
