import { UserService } from './../services/user.service';
import { Component, OnInit } from '@angular/core';
import { Page} from '../model/page';
import { WorkerItem} from '../model/WorkerItem';
import { WorkerService } from './../services/workerService';
import { CsvModule } from '@ctrl/ngx-csv';
import { MessagingSevice } from '../services/messagingService';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-workers',
  templateUrl: './workers.component.html',
  styleUrls: ['./workers.component.css']
})
export class WorkersComponent implements OnInit {


  page = new Page();
  rows = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  search = '';
  users: WorkerItem[]  = [];
  loading: any = true;
  sortcolumn = 'id';
  sortorder= 'desc';
  email = null;
  name = null;
  phone = null;
  agel  = null;
  ageg = null;
  status = null;
  userid = null;
  country = null;
  workertype=null;
  city = null;
  showAdvanced = false;
  nationalityid = null;
  countries : any[] = [  {'id': 206, 'name':'  United Arab Emirates', 'image':'/assets/icons/uae-round.png'},
  {'id': 166, 'name':'  Saudi Arabia', 'image':'/assets/icons/ksa-round.png'},
  {'id': 58, 'name':'  Egypt', 'image':'/assets/icons/egypt-round.png'}];
  cities = [
    {'id':21842,'name':'Alexandria','parent_id':58},
  {'id':21843,'name':'Cairo','parent_id':58},
  {'id':21838,'name':'AD+AIN+DXB+SHJ','parent_id':206},
  {'id':21839,'name':'Abu Dhabi','parent_id':206},
  {'id':21840,'name':'Sharjah','parent_id':206},
  {'id':21832,'name':'Dubai','parent_id':206}];
  constructor(private workerService: WorkerService, private messaging:MessagingSevice, private toastr: ToastrService,
    private userService:UserService) {
    this.page.pageNumber = 0;
    this.page.size = 50;
  }

  ngOnInit() {
       this.setPage({ offset: 0, pageSize:20 });

  } // end on init
  toggleAdvancedSearch(){
    this.showAdvanced = !this.showAdvanced;
  }
  filter() {
    this.page.size = this.page.size;
    this.page.pageNumber =this.page.pageNumber;
    this.workerService.getFullSearch(this.page, this.search, this.name, this.email , this.phone, this.nationalityid,
      this.country, this.city, this.userid, this.status, this.agel, this.ageg, this.workertype, this.sortcolumn, this.sortorder).then(pagedData => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      this.loading = false;
      this.loadingIndicator = false;

    });
  }
toggleUser(email, condition)
{
this.userService.toggleUser(email,true, condition).then(data=>{ this.toastr.success(`User was ${condition === 1? 'blocked':'unblocked'} successfully!`); this.filter();} ).catch(e=> this.toastr.error('Operation was not completed'));

}
  setPage(pageInfo) {
    console.log('Page Info:'+JSON.stringify(pageInfo) );
    this.page.size = pageInfo.pageSize;
    this.page.pageNumber = pageInfo.offset;
    //this.page
    this.workerService.getResult(this.page, this.search).then(pagedData => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      this.loading = false;
      this.loadingIndicator = false;
    });
  }

  getUser(email: string) {
    console.log('Called get user with email:' + email);
    this.messaging.GetIntercomUser(email).toPromise().then((data) => {
      console.dir(data);
    }).catch(e => console.log('Error in retriving nIntercom User' + JSON.stringify(e)));

  }

  onSort(event) {
    // event was triggered, start sort sequence
    console.log('Sort Event', event);
    this.loading = true;
    const sort = event.sorts[0];

    this.sortcolumn = sort.prop;
    this.sortorder = sort.dir;
    this.filter();
  }
}

