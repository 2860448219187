import { ProjectService } from './../services/projectService';
import { Component, OnInit } from '@angular/core';
import { User } from '../model/user';
import { Router } from '@angular/router';
import { CloudData, CloudOptions } from 'angular-tag-cloud-module';
import { Tag } from '../model/tag';
import { TagService } from '../services/tag.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  showDashBaord = false;
  User: User = null;
  role= 'admin';
  isSales = false;
  tags: Tag[] = null;
  reportId=null;
  reports = [
    {id:0, name: "Morning News", URL:"https://analytics.zoho.com/open-view/1926563000004302508/d87dd533380f6c1f40a7113fff7e9e43"},

    {id:1, name: "Default", URL:"https://analytics.zoho.com/open-view/1926563000000368108"},
    {id:2, name: "Hair Style", URL:"https://analytics.zoho.com/open-view/1926563000002527477"},
    
  ];
  dashboardURL = 'https://analytics.zoho.com/open-view/1926563000000368108';
  coptions: CloudOptions = {
    width: 850,
    height: 400,
    overflow: false,
    zoomOnHover: {
      scale: 1.2,
      transitionTime: 0.3,
      delay: .3
    },
    realignOnResize: true
  };
  data: CloudData[] =null;

  constructor(private projectService: ProjectService, private router: Router,private _tagService: TagService,) { }

  ngOnInit() {
    console.log('from home init!');
    if (localStorage.getItem('user') === null) this.router.navigate(['/']);
    this.User = JSON.parse(localStorage.getItem('user')) as User;
    this.role = this.User.email.indexOf('sale')>-1 ? 'sales': 'admin';
    if(this.role=== 'sales')this.getData();
    if (this.User.dashboard_url !== null && this.User.dashboard_url !== undefined && this.User.dashboard_url.length > 0) this.dashboardURL = this.User.dashboard_url;
    console.log('dashboard url is:' + this.dashboardURL);
  }

  getData() {
    this._tagService.getTags().then(data => {
      this.tags = data as Tag[];
      this.getWeight();
    });
  }

  getWeight() {
    let total = 0;
    this.data=[];
    this.tags.forEach(t => total += t.taggings_count);
    this.tags.forEach(t => t.weight = Math.ceil(t.taggings_count * 100 / total) + 3);
    this.tags.forEach(t=> this.data.push({id: t.id,text: t.name, weight: t.weight}));//, link: '/tags/'+t.id, color: 'orange'
  }

  changeURL(){
    const report =  this.reports.find(r=>r.id === +this.reportId);
    this.dashboardURL =report.URL;
  }

  nodeClicked(node: CloudData){
    this.router.navigate(['/','tags',node.id])
    console.log(node);
  }

}
