import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import  { API } from 'aws-amplify';
import { Area } from '../model/Area';
@Injectable({
  providedIn: 'root'
})
export class AreaService {

  constructor(public loader: LoadingBarService) { }
  public getAreas(): Promise<any> {
    const apiName = 'api';
    const path = `areas`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          this.loader.complete();
          resolve(data.data);
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });
    }); // end promoise
    return promise;
  } // end getresult
  public saveArea(model: Area) {
    const apiName = 'api';
    const path = 'areas';
    const myInit = {
      body: model,
      headers: {}
    };
    this.loader.start();

    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {
          this.loader.complete();

          const data = response;
          resolve(data);
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
}
