import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import  { API } from 'aws-amplify';
import { Report } from '../model/Report';
@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(public loader: LoadingBarService) { }
  public getReports(project: number, status: number, search: string): Promise<any> {
    const apiName = 'api';
    const path = `reports?1=1${project ? '&project=' + project : ''}${search ? '&r=' + search : ''}${status ? '&status=' + status : ''}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    const promise = new Promise((resolve, reject) => {

      API.get(apiName, path, myInit)
        .then(response => {
          //sprint = Convert.toSprint(JSON.stringify(response.data.data[0]));
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });

    });
    return promise;
  }
  public saveReport(model: Report) {
    const apiName = 'api';
    const path = `report`;
    const myInit = {
      body: model,
      headers: {}
    };
    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          resolve('OK');
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
}
