import { Component, OnInit } from '@angular/core';
import { User } from '../model/user';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  loading:boolean = true;
  constructor() { }
  user:User = null;
  model:User = null;
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user')) as User;
  this.model = this.user;
  }

}
