import { AgentService } from './../services/agent.service';
import { UserService } from './../services/user.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ProjectService } from '../services/projectService';
import { ToastrService } from 'ngx-toastr';
import { Page } from '../model/page';
import { User } from '../model/user';
@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css']
})
export class AgentsComponent implements OnInit {
  loadingIndicator = true;
  message: any = '';
  model: any = { id: 0, first_name: '', last_name: '', country_id: 206, phone_number: '+9715000000', created_at: new Date(), updated_at: new Date(), is_deleted: false };
  rows: any[];
  users: User[];
  id: number = 0;
  country: number = 0;
  newPassword = '';
  emailPassword = '';

  constructor(private agentService: AgentService,private  toastr: ToastrService, private userService: UserService) {
    this.model = this.GetUser(0);

  }

  ngOnInit() {
    this.GetAgents();
  }
  GetAgents() {
    this.loadingIndicator = true;
    this.agentService.getAgents().then((data) => {
      this.rows = data;
      this.users = data;
      this.loadingIndicator = false;
    }).catch((e) => this.toastr.error('Could Not Retrive Agents!'))
  }

  filter() {
    this.rows = this.country == 0 ? this.users : this.users.filter(s => s.country_id == this.country);
  }
  toggleUser(email, condition)
  {
  this.userService.toggleUser(email, false, condition).then(data=>
  {
    this.toastr.success(`User was ${condition === 1? 'blocked':'unblocked'} successfully!`);
    //this.users.filter(u=>u.email === email).is_blocked = condition ===1;
    this.GetAgents();
  }).catch(e=> this.toastr.error('Operation was not completed'));

  }
  changePassword(){
    if(this.emailPassword === null || this.emailPassword === '' || this.emailPassword === ''){
      this.toastr.error('Enter all the requied params!')
    }
    this.userService.resetPassword(this.emailPassword, this.newPassword, false).then(data=>
      {
        this.toastr.success(`Password was changed successfully!`);
      }).catch(e=> this.toastr.error('Operation was not completed'));
  }
  setEmailPassword(email) { this.emailPassword = email; }
  GetUser(id) {
    //console.log(id);
    if (id === 0) this.model = { id: 0, first_name: '', last_name:'', email:'', country_id: null, phone_number:'+97150000000', created_at: new Date(), updated_at: new Date(), is_deleted: false };
    else this.model= this.rows.find(r => r.id === id);
  }

  postForm() {
    this.agentService.saveAgent(this.model)
      .then((data) => {
        this.model.id = data['id'];
        console.log('Saved..' + data);
        this.toastr.success('Saved Successfully!');
      })
      .catch(err => {
        this.toastr.error('Error in saving reward');
        console.error(err);
      });
  }

}

