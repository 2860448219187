import { WorkerItem } from './../model/WorkerItem';
import { Worker } from './../model/Worker';

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PagedData } from '../model/paged-data';
import { Page } from '../model/page';
import Amplify, { API, Auth } from 'aws-amplify';
import aws_exports from './../../aws-exports';
import { AuthService } from '../auth/auth.service';
import { Convert } from '../model/Worker';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { worker } from 'cluster';

/**
 * A server used to mock a paged data result from a server
 */
@Injectable()
export class WorkerService {
   _worker: Worker = null;
  constructor(private auth: AuthService, public loader: LoadingBarService) {

  }
  /**
   * A method that mocks a paged server response
   * @param page The selected page
   * @returns {any} An observable containing the employee data
   */
  public getResults(page: Page, search?: any): Observable<PagedData<WorkerItem>> {
    return of(this.getPagedData(page, search));
  }
  public getWorker(email: string, id: number): Promise<any> {
    let worker: Worker = null;
    const apiName = 'api';
    const path = `worker/id?email=${email}&id=${id}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    const promise = new Promise((resolve, reject) => {
      this.loader.start();
      API.get(apiName, path, myInit)
        .then(response => {
          this.loader.complete();
          //worker = Convert.toWorker(JSON.stringify(response.data[0]))[0];
          resolve(response.data);
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });

    });
    return promise;
  }
  public getWorkerByEmail(email: string): Promise<any> {
    let worker: Worker = null;
    const apiName = 'api';
    const path = `worker?email=${email}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    const promise = new Promise((resolve, reject) => {
      this.loader.start();
      API.get(apiName, path, myInit)
        .then(response => {
          this._worker = response.data as Worker;
          localStorage.setItem('worker', JSON.stringify(this._worker));
          this.loader.complete();
          //worker = Convert.toWorker(JSON.stringify(response.data[0]))[0];
          resolve(response.data);
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });

    });
    return promise;
  }
  public saveWorker(worker: Worker) {
    const apiName = 'api';
    const path = `worker`;
    const myInit = {
      body: worker,
      headers: {}
    };
    const promise = new Promise((resolve, reject) => {
      this.loader.start();
      API.post(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          resolve('OK');
          this.loader.complete();
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
  public toggleWorker(email, condition) {
    const apiName = 'api';
    const path = condition ? 'user/toggle' : 'user/toggle';
    const myInit = {
      body: { "email": email, "condition": condition },
      headers: {}
    };
    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {
          const data = response.data;

          resolve('OK');
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
  public getResult(page: Page, search: string): Promise<any> {
    const pagedData = new PagedData<WorkerItem>();
    const apiName = 'api';
    const s = (search === null || search === '' || search === 'null') ? '' : '&search=' + search;
    const path = `workers?limit=${page.size}&offset=${page.pageNumber * page.size}${s}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          page.totalElements = data.count;
          page.totalPages = page.totalElements / page.size;
          this.loader.complete();
          const start = page.pageNumber * page.size;
          const end = Math.min((start + page.size), page.totalElements);
          pagedData.page = page;
          pagedData.data = data.data;
          resolve(pagedData);
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });
    }); // end promoise
    return promise;
  } // end getresult

  public getFullSearch(page: Page, search: string, name: string, email: string, phone: string, nationalityid: string, 
    countryid: any, cityid: any, userid: any, status: any, agel: any, agea: any, workertype: any,
    sortcolumn: string, sortorder: string): Promise<any> {
    const pagedData = new PagedData<WorkerItem>();
    const apiName = 'api';
    const s = (search === null || search === '' || search === 'null') ? '' : '&search=' + search;
    const al = (agel === null || agel === '' || agel === 'null') ? '' : '&agel=' + agel;
    const ag = (agea === null || agea === '' || search === 'null') ? '' : '&ageg=' + agea;
    const n = (name === null || name === '' || name === 'null') ? '' : '&name=' + name;
    const e = (email === null || email === '' || email === 'null') ? '' : '&email=' + email;
    const p = (phone === null || phone === '' || phone === 'null') ? '' : '&phone=' + phone;
    const nat = (nationalityid === null || nationalityid === '' || nationalityid === 'null') ? '' : '&nationalityid=' + nationalityid;
    const count = (countryid === null || countryid === '' || countryid === 'null') ? '' : '&countryid=' + countryid;
    const city = (cityid === null || cityid === '' || cityid === 'null') ? '' : '&cityid=' + cityid;
    const u = (userid === null || userid === '' || userid === 'null') ? '' : '&userid=' + userid;
    const sts = (status === null || status === '' || status === 'null') ? '' : '&status=' + status;
    const so = (sortorder === null || sortorder === '' || sortorder === 'null') ? '' : '&sortorder=' + sortorder;
    const sc = (sortcolumn === null || sortcolumn === '' || sortcolumn === 'null') ? '' : '&sortcolumn=' + sortcolumn;
    const wt = (workertype === null || workertype === '' || workertype === 'null') ? '' : '&workertype=' + workertype;

    const path = `workers/full?limit=${page.size}&offset=${page.pageNumber * page.size}${s}${al}${ag}${n}${e}${p}${nat}${count}${city}${u}${sts}${so}${sc}${wt}`;
    console.log(path);
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          this.loader.complete();
          page.totalElements = data.count;
          page.totalPages = page.totalElements / page.size;
          const start = page.pageNumber * page.size;
          const end = Math.min((start + page.size), page.totalElements);
          pagedData.page = page;
          pagedData.data = data.data;
          resolve(pagedData);
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });
    }); // end promoise
    return promise;
  } // end getresult

  /**
   * Package companyData into a PagedData object based on the selected Page
   * @param page The page data used to get the selected data from companyData
   * @returns {PagedData<CorporateEmployee>} An array of the selected data and page
   */
  private getPagedData(page: Page, search): PagedData<WorkerItem> {
    const pagedData = new PagedData<WorkerItem>();
    const apiName = 'api';
    const path = `workers?search=${search}&limit=${page.size}&offsert=${page.pageNumber * page.size}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();
    API.get(apiName, path, myInit)
      .then(response => {
        this.loader.complete();
        const data = response.data;
        page.totalElements = data.count;
        page.totalPages = page.totalElements / page.size;
        const start = page.pageNumber * page.size;
        const end = Math.min((start + page.size), page.totalElements);
        pagedData.data = data.data;
      })
      .catch(error => {
        console.log('Error in invoiking API:' + error);
      });
    pagedData.page = page;
    return pagedData;
  }

}
