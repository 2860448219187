import { ProjectService } from './../services/projectService';
import { ReportService } from './../services/report.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Report } from '../model/Report';
import { Sprint } from '../model/sprint';
import { ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { bool } from 'aws-sdk/clients/signer';
import { Page } from '../model/page';


@Component({
  selector: 'app-reportsadmin',
  templateUrl: './reportsadmin.component.html',
  styleUrls: ['./reportsadmin.component.css']
})
export class ReportsadminComponent implements OnInit {
  rows = [];
  projectId: number = null;
  statusId: number = null;
  projects = [];
  search: string = null;
  @Input() admin: boolean = false;

  message: any = '';
  model: Report = null;
  id: number =0;
  loadingIndicator: boolean = true;



  constructor(private reportService: ReportService, private projectService: ProjectService,private  toastr: ToastrService) { }

  ngOnInit() {
    this.GetRecords();
    this.GetRecord(0);
    this.GetProjects();
  }
  GetProjects(){
    let p = new Page();
    p.pageNumber = 0;
    p.totalElements = 100;
    p.totalPages = 1;
    p.size = 100;
    this.projectService.getResult(p, null, this.search, null, null,1).then(pagedData => {
      this.projects = pagedData.data;
    });
  }
  GetRecord(id) {
    if (id === 0) this.model = {
      id: 0, title: '', description: '', url: '', status: 0, reportable_id: null,
      reportable_type: 'Project', creator_id: null, creator_type: 'Agent', attributes: null, created_at: new Date(),
       updated_at: new Date(), is_deleted: false
    };
    else this.model = this.rows.find(r => r.id === id);
  }

GetRecords(){
  this.reportService.getReports(this.projectId, this.statusId, this.search).then((data)=> {
    this.loadingIndicator = false;
    this.rows=data;
  });
}
postForm(){
  this.model.reportable_id  = this.projectId;
  this.model.reportable_type= 'Project';
  this.reportService.saveReport(this.model)
    .then((data) => {
      this.model.id = data['id'];
      console.log('Saved..' + data);
      this.toastr.success('Saved Successfully!');
     this.GetRecords();
    })
    .catch(err => {
      this.toastr.error('Error in saving reward');
      console.error(err);
    } );
}
}
