import { bool } from 'aws-sdk/clients/signer';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import Amplify from '@aws-amplify/core';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import aws_exports from './../../aws-exports';
import { AuthService } from '../auth/auth.service';
import { User } from '../model/user';
import { LoadingBarService } from '@ngx-loading-bar/core';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  User: User = null;
  constructor(private auth: AuthService, public loader: LoadingBarService) { }
  public getUser(email): Promise<any> {
    const apiName = 'api';
    const path = `user?email=${email}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then(response => {
          this.loader.complete();
          const data = response.data;
          try {
            this.User = data.db[0] as User;
            localStorage.setItem('user', JSON.stringify(this.User));
            this.loader.complete();
          }
          catch (x) {
            console.log('error in retrieving agent' + x);this.loader.complete();
          }

          resolve(this.User);
        })
        .catch(error => {
          this.loader.start();

          console.log('Error in invoiking API:' + error);
          reject(error);
        });
    }); // end promoise
    return promise;
  } // end getUser

  public resetPassword(email: string, newpassword: string, forWorker : bool): Promise<any> {
    const apiName = 'api';
    const path = `user/reset`;
    const myInit = {
      body: {email: email, password: newpassword, worker: forWorker},
      headers: {}
    };
    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {

          const data = response;
          resolve(data);
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }

  public toggleUser(email: string, forWorker: bool, condition: number): Promise<any> {
    const apiName = 'api';
    const path = `user/toggle`;
    const myInit = {
      body: {email: email, worker: forWorker, condition: condition},
      headers: {}
    };
    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {

          const data = response;
          resolve(data);
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }

}
