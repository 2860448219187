import { GoogleService } from './services/google.services';
import { MessagingSevice } from './services/messagingService';
import { Toastr, TOASTR_TOKEN } from './services/toastr.service';
import { JQ_TOKEN } from './services/jQuery.service';
import { PaymentRequestService } from './services/paymentRequestService';
import { WorkerService } from './services/workerService';
import { ProjectService } from './services/projectService';
import { AccountService } from './services/accountService';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { AppComponent  } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxUploaderModule } from 'ngx-uploader';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { HomeComponent } from './home/home.component';
import { UsersComponent } from './users/users.component';
import { AccountsComponent } from './accounts/accounts.component';
import { PaymentRequestsComponent } from './paymentrequests/paymentrequests.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CsvModule } from '@ctrl/ngx-csv';import { SubmissionService } from './services/submissionService';
import { TransactionService } from './services/transactionService';
import { TransactionsComponent } from './Transactions/transactions.component';
import { SprintComponent } from './sprint/sprint.component';
import { SubmissionsComponent } from './submissions/submissions.component';
import { RewardsComponent } from './rewards/rewards.component';
import { TaskformsComponent } from './taskforms/taskforms.component';
import { TasksComponent } from './tasks/tasks.component';
import { SprinteditComponent } from './sprintedit/sprintedit.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill'
import { Ng5SliderModule } from 'ng5-slider';
import { TaskEditComponent } from './task-edit/task-edit.component';
import { SprintsComponent } from './sprints/sprints.component';
import { TaskFormComponent } from './task-form/task-form.component';
import { QuestionComponent } from './question/question.component';
import { ModalTriggerDirective } from './services/modalTrigger.directive';
import { SimpleModalComponent } from './services/simpleModal.component';
import { SubmissionComponent } from './submission/submission.component';
import { ReportComponent } from './report/report.component';
import { WorkerComponent } from './worker/worker.component';
import { ToastrModule } from 'ngx-toastr';
import { FileUploadComponent } from './services/FileUploadComponent';
import { AreaComponent } from './area/area.component';
import { AreasComponent } from './areas/areas.component';
import { AgmCoreModule } from '@agm/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { AgentsComponent } from './agents/agents.component';
import { WorkersComponent } from './workers/workers.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsadminComponent } from './reportsadmin/reportsadmin.component';
import { AgentComponent } from './agent/agent.component';
import { NgxSortableModule } from 'ngx-sortable'
import { SafePipe} from './safe.pipe';
import { TomtomComponent } from './tomtom/tomtom.component';
import {DatePipe} from '@angular/common';
let toastr:Toastr = window['toastr'];
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { AgWordCloudModule } from 'angular4-word-cloud';


import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoaderComponent } from './loader/loader.component';
import { SearchPipe } from './search.pipe';
import { SortbyPipe } from './sortby.pipe';
import { TagsComponent } from './tags/tags.component';
import { TreeModule } from 'angular-tree-component';
import { TagcloudComponent } from './tagcloud/tagcloud.component';
import { InsightsComponent } from './insights/insights.component';
import { TagCloudModule } from 'angular-tag-cloud-module';
import { DemographicsComponent } from './demographics/demographics.component';

let jQuery = window['$'];
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [AppComponent, LoginComponent, SignupComponent, HomeComponent, UsersComponent,
                   AccountsComponent, SafePipe ,PaymentRequestsComponent, TransactionsComponent, SprintComponent,
                  SubmissionsComponent,
                  RewardsComponent,
                  TaskformsComponent,
                  TasksComponent,
                  SprinteditComponent,
                  TaskEditComponent,
                  SprintsComponent,
                  TaskFormComponent,
                  QuestionComponent,
                  ModalTriggerDirective,
                  SimpleModalComponent,
                  SubmissionComponent,
                  ReportComponent,
                  WorkerComponent,
                  FileUploadComponent,
                  AreaComponent,
                  AreasComponent,
                  DashboardComponent,
                  ProfileComponent,
                  SettingsComponent,
                  AgentsComponent,
                  WorkersComponent,
                  ReportsComponent,
                  ReportsadminComponent,
                  AgentComponent,
                  LoaderComponent,
                  TomtomComponent,
                  SearchPipe,
                  SortbyPipe,
                  TagsComponent,
                  TagcloudComponent,
                  InsightsComponent,
                  DemographicsComponent
                                    ],
  imports: [
    NgxDatatableModule,TagCloudModule,
    AgWordCloudModule.forRoot(),
    BrowserModule,
    AmplifyAngularModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CsvModule,
    NgxSelectModule,
    NgxUploaderModule,
    OwlDateTimeModule,
    NgxSortableModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    QuillModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    Ng5SliderModule,
    //TagCloudModule,
    TreeModule.forRoot(),
    NgbModule.forRoot(),
    ToastrModule.forRoot(), // ToastrModule added
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDP8ctBV6hZ-3zSmr7enXzQTHn5jFd_guc',
      libraries: [ 'drawing']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AmplifyService,
    AuthService,
    AuthGuard,
    WorkerService,
    AccountService,
    ProjectService,
    PaymentRequestService,
    SubmissionService,
    DatePipe,
    TransactionService, MessagingSevice, GoogleService,
    { provide: TOASTR_TOKEN, useValue: toastr },
    { provide: JQ_TOKEN, useValue: jQuery }]
  ,bootstrap: [AppComponent]
})
export class AppModule {}
