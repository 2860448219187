import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css']
})
export class AgentComponent implements OnInit {
  countries : any[] = [  {'id': 206, 'name':'  United Arab Emirates', 'image':'/assets/icons/uae-round.png'},
                      {'id': 166, 'name':'  Saudi Arabia', 'image':'/assets/icons/ksa-round.png'},
                      {'id': 58, 'name':'  Egypt', 'image':'/assets/icons/egypt-round.png'}];

  loading = true;
  email = null;
  id = null;
  model: any =null;
  constructor() { }

  ngOnInit() {
  }

}
