import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})


export class ReportComponent implements OnInit {
  viz: any;

  constructor() { }

  ngOnInit() {
    const placeholderDiv = document.getElementById('vizContainer');
    const url = '';// 'https://dub01.online.tableau.com/t/muhimma/views/Julphar_CrossTabs/AgreeToShareAnswer?iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no';
    const options = {
        hideTabs: true,
        width: "80%",
        height: "500px",
        onFirstInteractive: function() {
              console.log("Run this code when the viz has finished     loading.");
                }
        };
  //  this.viz = new tableau.Viz(placeholderDiv, url, options);
  }

}
