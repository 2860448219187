import { ProjectService } from './../services/projectService';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Reward, Sprint } from '../model/sprint';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.css']
})
export class RewardsComponent implements OnInit {
  amount = 10;
  currency =10;
  message: any = '';
  model: Reward = null;
  _sprint : Sprint  = null;
  id: number =0;
  @Input() sprintId: number;
  @Output('RewardSaved') valueChange: EventEmitter<Reward> = new EventEmitter<Reward>();

 @Input() rows: any[];
//  @Input('Sprint')
//   set sprint(value: Sprint) {
//     this._sprint = value;
//     //this.valueChange.emit(this._model);
//   }

  constructor(private projectService: ProjectService,private  toastr: ToastrService) { }

  ngOnInit() {
    this.GetReward();
  }
  GetReward(){
if(this.id === 0 || this._sprint.rewards == null ) this.model = { id: 0, title: '', project_id: this.sprintId, is_shown: true, amount: 0, currency_id:10, created_at: new Date(), updated_at : new Date(), is_deleted:false};
else return this._sprint.rewards.find(r=>r.id === this.id);
}
postForm(){
  this.projectService.saveReward(this.model)
    .then((data) => {
      this.model.id = data['id'];
      console.log('Saved..' + data);
      this.toastr.success('Saved Successfully!');
      this.valueChange.emit(this.model);
    })
    .catch(err => {
      this.toastr.error('Error in saving reward');
      console.error(err);
    } );
}

}
