import { Component, OnInit } from '@angular/core';
import { BreadCrumb } from '../model/BreadCrumb';
import { Router } from '@angular/router';

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.css']
})
export class DemographicsComponent implements OnInit {
  crumbs: BreadCrumb[];

  constructor(private router: Router) { }

  ngOnInit() {
    this.buildBreadCrumb();
  }

  buildBreadCrumb() {
    //Sprints? Sprint()>Task Forms ()
    this.crumbs = [];
      this.crumbs.push(new BreadCrumb('Home','/'));
      this.crumbs.push(new BreadCrumb('Demographics','/demographics'));
      }
      back(){
this.router.navigate(['/home']);
      }

}
