import { Tag } from './../model/tag';
import { ProjectService } from './../services/projectService';
import { Question, Form, Opt,Type } from './../model/sprint';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { getLocaleDateFormat } from '@angular/common';
import { GoogleService, GoogleObj } from '../services/google.services';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styles:[ `
    .custom-control { display: inline-block; margin-left: 5px; }
    ` ],
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {
  bulkoptions: any ='';
  loading: boolean=false;
  _model: Question = null;
  _tags: Tag[] = null;
  @Output('QuestionSaved') valueChange: EventEmitter<Question> = new EventEmitter<Question>();

  @Input('Question')
  set model(value: Question) {
    this._model = value;
    //this.valueChange.emit(this._model);
  }
  @Input('Tags')
  set Tag(value: Tag[]) {
    this._tags = value;
    //this.valueChange.emit(this._model);
  }
  get model() {
    if(this._model === null || this._model === undefined) {
        this._model =  { task_form_id : 0, question_type:1, is_visible_client: false, max_answer_points: 0,
          constraints: { is_required: false, limit:{ type: Type.Character, min:10}}, description:'', id:0 , opts :null, label:  null, labelar : null, position : 100, answer_points: 0,
          is_required : false, is_deleted : false, is_random : false, created_at : new Date(), updated_at:new Date()};
       }//end if
    return this._model;
  }
Options: Opt ;
public googleObj: GoogleObj = new GoogleObj();
public key: string;
public result = '';
  @Input('Types') Types: any[];
  @Input('TaskForm') TaskForm: Form ;
  constructor(private projectService: ProjectService, private _google: GoogleService) {

  }
translate(){
  this.doTranslate(this._model.label).then((d)=>{ this._model.labelar = d.toString();});
  this.model.opts.forEach(element => {
    this.doTranslate(element.label).then((d)=>{ element.labelar = d.toString();});

  });
}
  doTranslate(key) {
    return new Promise((resolve, reject) => {

    this._google.translate(this.googleObj,key).subscribe(
      (res: any) => {
        resolve(res.data.translations[0].translatedText);
      },
      err => {
        console.log(err);
        reject(err);

      }
    )});
  }
  ngOnInit() {
    console.warn('from init '+ this.Types.length);

    console.dir(this.TaskForm);
  }
  onSubmit() {
    console.log('Question Form Submitted!');
  }
  showOptions() {
 if(this.model.question_type === 0 || this.model.question_type === 1 || this.model.question_type === 4 ||
  this.model.question_type === 4 || this.model.question_type === 8 ||
  this.model.question_type === 19 || this.model.question_type === 21   ) return true;
  return false;
  }
  showContraints() {
    if(this.model.question_type === 5 || this.model.question_type === 9 ) return true;
     return false;
     }

     postForm(){;
       this.model.constraints =   { is_required: this.model.is_required, limit:{ type: Type.Character, min:10}}
       this.model.task_form_id = this.TaskForm.id;
      this.projectService.saveQuestion(this.model)
                          .then((data)=> {console.log(JSON.stringify(data));this.valueChange.emit(this.model);})
                          .catch((err)=>{ console.error('Error in saving questions: '+err); });
     }
     compareFn(c1, c2): boolean {
       ;
       console.log(c1, c2);
      // return  c1.position === c2.position;
      return c1 && c2 ? c1.position === c2.position : c1 === c2;
 }
        deleteQuestion(){
          if(confirm("Are you sure to delete "+this.model.label)) {
            this.model.task_form_id = this.TaskForm.id;
            this.model.is_deleted = true;
            this.projectService.saveQuestion(this.model)
                                .then((data)=> {console.log(JSON.stringify(data));this.valueChange.emit(this.model);})
                                .catch((err)=>{ console.error('Error in saving questions: '+err); });
          }

     }
     addOption(){
       let p = 1;
       if(this.model.opts === null || this.model.opts === undefined) this.model.opts = [];
       if(this.model.opts.length>0) p = this.model.opts.length+1;
      // console.log(JSON.stringify(this.model.opts));
       this.model.opts.push(
         { id: null, created_at: new Date(), updated_at: new Date(), jump_ids: [], label: '', labelar:'',
            answer_points:0, position: p, question_id : this.model.id, optional_label:'', has_extra_payload: false}
       );

       console.log(JSON.stringify(this.model.opts));

     }
     processBulk(){
      let p = 1;
      if(this.model.opts === null || this.model.opts === undefined) this.model.opts = [];
      if(this.model.opts.length>0) p = this.model.opts.length+1;
     let texts = this.bulkoptions.split(',');
     texts.forEach(element => {
      this.model.opts.push(
        { id: null, created_at: new Date(), updated_at: new Date(), jump_ids: [], label: element, labelar:element,
           answer_points:0, position: p, question_id : this.model.id, optional_label:'', has_extra_payload: false}
      );
      ++p;
     });

      console.log(JSON.stringify(this.model.opts));

     }
     /////////////////////////////////////////////////////////////////////

     ////////////////////////////////////////////////////////////////////

}
