import { ProjectService } from './../services/projectService';
import { SubmissionService } from './../services/submissionService';
import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Sprint, Reward } from '../model/sprint';
import { BulkEvaluation } from '../model/PaymentRequestForm';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.css'],
  styles: ['agm-map { height: 300px; /* height is required */ }'],

})
export class SubmissionComponent implements OnInit {
  id: number = 1;
  message = '';
  imgUrl: any = '';
  rewards: Reward[] = null;
  mapView: boolean = false;
  //  model: any;
  @Input() sprintID: number;
  @Input() previous: number;
  @Input() next: number;
  shouldredirect: boolean = false;

  email: string = 'affan@muhimmaapp.com';
  loading: boolean = true;
  rewardId = null;
  _model: any = null;
  @Output('SubmissionSaved') valueChange: EventEmitter<number> = new EventEmitter<number>();
  @Output('SubmissionBinded') binded: EventEmitter<number> = new EventEmitter<number>();

  @Input('Submission')
  set model(value: any) {
    if (!value) return;
    //console.log(typeof(value.latitude));
    let lat = value.latitude;
    if (lat < 1) {
      //lat = lat.replace("0.","");
      //lat = lat.slice(0, 2) + "." + lat.slice(2);
      lat = lat * 100;
      console.log('latitude', lat);
      value.latitude = lat;
    }
    this._model = value;

    //console.log('called set model::' + JSON.stringify(this._model));
    this.binded.emit(0);
    //this.valueChange.emit(this._model);
  }

  get model() {
    return this._model;
  }
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private submissionService: SubmissionService, private projectService: ProjectService, private toastr: ToastrService) {
    this.shouldredirect = router.url.indexOf("submission") > -1;

  }
  @ViewChild('closeModal') closeModal: ElementRef

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = +this.activatedRoute.snapshot.paramMap.get('submission');
      this.sprintID = +this.activatedRoute.snapshot.paramMap.get('id');
      this.getRewards();

      if (this.id > 0) {
        this.submissionService.getById(this.email, this.id).then(submission => {
          this.model = submission.data[0];
          this.loading = false;
        });
      }
    });
  }
  showMap: boolean = false;
  toggleMap() {
    this.showMap = !this.showMap;
  }
  styleFunc(feature) {
    return ({
      clickable: false,
      fillColor: feature.getProperty('color'),
      strokeWeight: 1
    });
  }
  setImageUrl(url) {
    this.imgUrl = url;
  }
  stripHtml(text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }
  getRewards() {
    if(this._model === null) return;
    this.projectService.getSprint('joanna@muhimmaapp.com', this.sprintID).then(sprint => {
      this.rewards = sprint.rewards;
      this.rewardId = sprint.tasks.find(r => r.id === this._model.task_id).reward_id;
    });
  }
  getStatus(status) {
    if (status === 0) return '<span class="badge badge-light">Draft</span>';
    if (status === 1) return '<span class="badge badge-warning">Submitted</span>';
    if (status === 2) return '<span class="badge badge-success">Approved</span>';
    if (status === 3) return '<span class="badge badge-danger">Rejected</span>';
    if (status === 4) return '<span class="badge badge-secondary">Withdrawn</span>';

    return '<span class="badge badge-info">-</span>';
  }
  getImageHtml(a) {
    let html = '';
    if (a === undefined || a === null) return '';
    let urls = a.split(',');
    for (let x = 0; x < urls.length; x++) {
      html += '<img src="' + urls[x] + '" width="60px" height="60px" data-toggle="modal" data-target="#imageModal" class="img-fluid img-thumbnail"  (click)="setImageUrl(\'' + urls[x] + '\')"    />';
    }
    return html;
  }
  getURLs(a) {
    if (a === undefined || a === null) return '';
    let urls = a.split(',');
    return urls;
  }
  getAnswer(answer) {
    let html = answer.answer;
    let a = answer.answer;
    if (a !== null && a !== undefined && a.indexOf('.m4a') > -1) {
      a = a.replace("/files", "/audios");
      a = a.replace(".m4a", ".mp3");
    }
    if (answer.question_type === 7 || answer.question_type === 6 || answer.question_type === 8 || answer.question_type === 9) html = this.getImageHtml(answer.answer);
    if (answer.question_type === 12) html = '<audio src="' + a + '" controls preload="auto"  ></audio>';

    return html;
  }

  getQuestionType(answer) {
    let s = 'fas fa-info';

    try { s = this.QuestionTypes.filter(qt => qt.id === answer.question_type)[0].class; }
    catch (e) { }
    let html = '<i class="' + s + '"></i>';
    return html;
  }
  getEvaluation(evaluation) {
    if (evaluation.evaluation_type === 0) return '<span class="badge badge-success">Accepted</span>';
    else return '<span class="badge badge-danger">Rejected</span>';
  }

  approve() {
    console.log('Clicked approve');
    let ids = [];

    ids.push(this.model.id);
    let form = new BulkEvaluation(ids, 2, this.message, 4, this.rewardId);
    this.submissionService.saveSubmissions(form).then((result) => {
      this.redirect();
      this.toastr.success('Submission Approved');
    }).catch((err) => {
      this.redirect();
    });
    // this.submissionService.saveSubmission(this.model.id, 4, 2).then((result) => {
    //   this.redirect();
    // }).catch((err) => {
    //   this.redirect();
    // });
  }

  reject() {
    console.log('Clicked reject');
    let ids = [];
    ids.push(this.model.id);
    let form = new BulkEvaluation(ids, 3, this.message, 4, this.rewardId);
    this.submissionService.saveSubmissions(form).then((result) => {
      this.toastr.success('Submission Rejected');
      this.redirect();
      this.closeModal.nativeElement.click() //<-- here

    }).catch((err) => {
      this.redirect();
    });
    this.closeModal.nativeElement.click() //<-- here

  }
  openURL(id) {
    window.open('https://muhimmaplatform.com/workers/' + id + '/edit');
  }
  redirect() {
    console.log('got too the redirect');

    if (this.shouldredirect) this.router.navigate(['/sprints/', this.sprintID]);
    this.valueChange.emit(0);
  }


  QuestionTypes = [
    { 'id': 0, 'name': 'MultiChoice', 'namear': 'MultiChoice', 'class': 'far fa-check-square' },
    { 'id': 1, 'name': 'DropDownList', 'namear': 'DropDownList', 'class': 'fas fa-caret-square-down' },
    { 'id': 2, 'name': 'Number', 'namear': 'Number', 'class': 'fas fa-caret-square-down' },
    { 'id': 3, 'name': 'Paragraph', 'namear': 'Paragraph', 'class': 'fas fa-paragraph' },
    { 'id': 4, 'name': 'SingleSelection', 'namear': 'SingleSelection', 'class': 'fas fa-caret-square-down' },
    { 'id': 5, 'name': 'Text', 'namear': 'Text', 'class': 'fas fa-ad' },
    { 'id': 6, 'name': 'ImageUpload', 'namear': 'ImageUpload', 'class': 'fas fa-image' },
    { 'id': 7, 'name': 'MultipleImageUpload', 'namear': 'MultipleImageUpload', 'class': 'fas fa-images' },
    { 'id': 8, 'name': 'MultiChoice', 'namear': 'MultiChoice', 'class': 'fas fa-images' },
    { 'id': 9, 'name': 'MultipleImageCapture', 'namear': 'MultipleImageCapture', 'class': 'fas fa-images' },
    { 'id': 10, 'name': 'LocationStamp', 'namear': 'LocationStamp', 'class': 'fas fa-map-marker' },
    { 'id': 11, 'name': 'LocationMap', 'namear': 'LocationMap', 'class': 'fas fa-map' },
    { 'id': 12, 'name': 'VoiceRecording', 'namear': 'VoiceRecording', 'class': 'fas fa-microphone' },
    { 'id': 13, 'name': 'MultiChoice', 'namear': 'MultiChoice', 'class': 'far fa-check-square' },
    { 'id': 14, 'name': 'Phone', 'namear': 'Phone', 'class': 'fas fa-phone' },
    { 'id': 15, 'name': 'Date', 'namear': 'Date', 'class': 'far fa-calendar' },
    { 'id': 16, 'name': 'DateTime', 'namear': 'DateTime', 'class': 'fas fa-clock' },
    { 'id': 17, 'name': 'Range', 'namear': 'Range', 'class': 'fas fa-exchange-alt' },
    { 'id': 19, 'name': 'TagList', 'namear': 'TagList', 'class': 'fas fa-tags' },
    { 'id': 21, 'name': 'SelectionWithText', 'namear': 'SelectionWithText', 'class': 'fas fa-caret-square-down' },
    { 'id': 23, 'name': 'Infomation', 'namear': 'Infomation', 'class': 'fas fa-info' },
  ];
}
