import { SubmissionService } from './../services/submissionService';
import { BulkEvaluation } from './../model/PaymentRequestForm';

import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { Page } from '../model/page';
import { CsvModule } from '@ctrl/ngx-csv';
import { Router } from '@angular/router';

@Component({
  selector: 'app-submissions',
  templateUrl: './submissions.component.html',
  styleUrls: ['./submissions.component.css']
})
export class SubmissionsComponent implements OnInit {
  @Input() projectId: number;
  workerId = null;
  rewardId = null;
  @ViewChild('closeModal') closeModal: ElementRef
  ids = [];

  page = new Page();
  selected = [];
  range = "0";
  idscommas = null;
  expanded: any = {};
  Submission: any = null;
  previous = 0;
  next = 0;
  @ViewChild('myTable') table: any;
  status = 1;
  reportable = -1;
  newstatus = 2;
  message = "";
  from = null;
  to = null;
  rows = [];
  count = 0;
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  email = 'affan@muhimmaapp.com';
  loading: any = true;
  shouldredirect: boolean = false;
  constructor(private submissionService: SubmissionService, router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 100;
  }

  ngOnInit() {
    this.setPage({ offset: 0, size: 100 });

  } // end on init
  filter() {
    const status = (this.status == -1 ? null : this.status);
    const reportable = (this.reportable == -1 ? null : this.reportable == 1);
    this.submissionService.getResult(this.page, this.email, this.workerId, status, null,
      this.projectId, reportable, this.from, this.to).then(pagedData => {
        this.page = pagedData.page;
        this.rows = pagedData.data ? pagedData.data : [];
        this.count = pagedData.page.totalElements;
        this.loadingIndicator = false;
      });
  }
  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }
  getSubmission(id) {
    this.loading = true;
    this.submissionService.getById(this.email, id).then(submission => {
      this.Submission = submission.data[0];
      console.log(this.Submission.longitude);
      console.log('Submission was retreiived');
      this.loading = false;
    });
  }
  mapids() {
    this.selected.map(s => this.ids.push(s.id));
    this.idscommas = this.ids.join(',');
  }

  postForm() {
    if (this.idscommas === null || this.idscommas === '') return;
    this.ids = this.idscommas.split(',');
    // this.selected.map(s => this.ids.push(s.id));
    let form = new BulkEvaluation(this.ids, this.newstatus, this.message, 1000, this.rewardId);
    this.submissionService.saveSubmissions(form);
    this.filter();
  }
  onSelect({ selected }) {
    //console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }
  setPage(pageInfo) {
    console.log(pageInfo);
    this.page.size = pageInfo.size;
    this.page.pageNumber = pageInfo.offset;
    this.filter();
  }//end setPage
  

  getId(id, status) {
    //console.log(id,status);

    if (status === 0) return '<i class="fas fa-circle text-dark"></i> ' + id;
    if (status === 1) return '<i class="fas fa-circle text-primary"></i> ' + id;
    if (status === 2) return '<i class="fas fa-circle text-success"></i> ' + id;
    if (status === 3) return '<i class="fas fa-circle text-danger"></i> ' + id;
    if (status === 4) return '<i class="fas fa-circle text-warning"></i> ' + id;
    if (status === 5) return '<i class="fas fa-circle text-muted"></i> ' + id;
    return '<span class="badge badge-info">-</span>';
  }
  getStatus(status) {
    if (status === 0) return '<span class="badge badge-warning">In Progress</span>';
    if (status === 1) return '<span class="badge badge-success">Approved</span>';
    if (status === 2) return '<span class="badge badge-danger">Rejected</span>';
    if (status === 3) return '<span class="badge badge-primary">Completed</span>';

    return '<span class="badge badge-info">-</span>';
  }

  setDateRange() {
    let dt = new Date();
    let month = dt.getMonth(), year = dt.getFullYear();
    if (this.range === "0") this.from = new Date(year, month, 1);
    if (this.range === "0") this.to = new Date(year, month + 1, 0);
    if (this.range === "1") this.from = new Date(year, month - 1, 1);
    if (this.range === "1") this.to = new Date(year, month + 1, 0);
    if (this.range === "2") this.from = new Date(year, month - 3, 1);
    if (this.range === "2") this.to = new Date(year, month + 1, 0);
    if (this.range === "3") this.from = new Date(year, 1, 1);
    if (this.range === "3") this.to = new Date(year + 1, 1, 0);
    this.filter();
  }
  openModal(a) {
    document.getElementById("openModalButton").click();

  }
  getSubmissionStatus(status) {
    if (status === 0) return '<span class="badge badge-light">Draft</span>';
    if (status === 1) return '<span class="badge badge-warning">Submitted</span>';
    if (status === 2) return '<span class="badge badge-success">Approved</span>';
    if (status === 3) return '<span class="badge badge-danger">Rejected</span>';
    if (status === 4) return '<span class="badge badge-secondary">Withdrawn</span>';

    return '<span class="badge badge-info">-</span>';
  }
  submissionSaved(question) {

    console.log('emitted the value now the popup should close' + this.closeModal.nativeElement);
    this.closeModal.nativeElement.click();
    this.filter();

  }
}
