import { File } from './../model/sprint';
import { S3Response } from './../model/S3Response';
import { AccountService } from './../services/accountService';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Sprint, File as Upload } from '../model/sprint';
import { Worker } from '../model/worker';

import { Page } from '../model/page';
import { ProjectService } from '../services/projectService';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { Options } from 'ng5-slider';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { base64Response } from '../model/Base64Reponse';
import { BreadCrumb } from '../model/BreadCrumb';
import { ToastrService } from 'ngx-toastr';
import { WorkerService } from '../services/workerService';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-worker',
  templateUrl: './worker.component.html',
  styles: ['agm-map { height: 300px; /* height is required */ }'],
})
export class WorkerComponent implements OnInit {
  uploadUrl:string = 'https://gateway.backendapis.net/node/file?name=' ;
  crumbs: BreadCrumb[];

  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  backroundFile : UploadFile;
  iconFile: UploadFile;
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  submitted = false;
  schedule = false;
  availability=false;
  minValue: number = 1;
  maxValue: number = 99;
  allowed= null;
  disallowed = null;
  from = null;
  to= null;
  sliderOptions: Options = {
    floor: 0,
    ceil: 100,
    step: 5
  };
  accounts = [];
  countries : any[] = [  {'id': 206, 'name':'  United Arab Emirates', 'image':'/assets/icons/uae-round.png'},
                      {'id': 166, 'name':'  Saudi Arabia', 'image':'/assets/icons/ksa-round.png'},
                      {'id': 58, 'name':'  Egypt', 'image':'/assets/icons/egypt-round.png'}];

  loading = true;
  email = null;
  id = null;
  model: any =null;
  showMap:boolean  = false;
  toggleMap(){
    this.showMap = !this.showMap;
  }

  constructor(private accountService: AccountService, private workerService: WorkerService,
              private activatedRoute: ActivatedRoute, private http:HttpClient, private router: Router,  private toastr: ToastrService) {
    this.filterAccounts(null);
    this.options = { concurrency: 1, maxUploads: 3 };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
   }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = this.activatedRoute.snapshot.paramMap.get('id');
      //console.log(this.email);
      if(this.id >0 ){
        this.workerService.getWorker(this.email, this.id).then( worker => {
          //worker.birthdate =  new DatePipe('en-US').transform(worker.birthdate, 'MM/dd/yyyy');
         // debugger;
          //console.dir(worker);
          this.model = worker;

          this.buildBreadCrumb();

          this.loading = false;
        });
      }
      else{
        this.loading = false;
      }
    });
  }
  onSubmit() { this.submitted = true; }
  getSubmissionStatus(status){
    if(status===0) return '<span class="badge badge-warning">Draft</span>';
    if(status===1) return '<span class="badge badge-primary">Submitted</span>';
    if(status===2) return '<span class="badge badge-success">Approved</span>';
    if(status===3) return '<span class="badge badge-danger">Rejected</span>';

    return '<span class="badge badge-info">-</span>';
  }
  getURL(name){
    return this.uploadUrl+ this.model.name.replace(/\s/g, '')+ name;
 }
 styleFunc(feature) {
  return ({
 clickable: false,
 fillColor: feature.getProperty('color'),
 strokeWeight: 1
 });
  }
  filterAccounts(search){
    const p = new Page();
    p.pageNumber = 0;
    p.totalElements = 100;
    p.totalPages = 1;
    p.size = 100;
    this.accountService.getResult(p, this.email).then(accounts => this.accounts = accounts.data);
  }
  buildBreadCrumb(){
    //Sprints? Sprint()>Task Forms ()
    this.crumbs = [];
      this.crumbs.push(new BreadCrumb('Home','/'));

      this.crumbs.push(new BreadCrumb('Workers','/workers'));
      this.crumbs.push(new BreadCrumb(this.model.first_name ? this.model.first_name  +' '+this.model.last_name + '('+this.model.id+')' : 'Worker' ,''));

      }
  ////////////////////////////////////////////////////////////
  onUploadOutput(output: UploadOutput, c: string): void {

    switch (output.type) {
      case 'allAddedToQueue':
          let file = (c === 'icon') ? this.iconFile : this.backroundFile;

          let name = file ? file.name :   (new Date()).getTime() + '.jpeg';
          let reader = new FileReader();
          name =  this.model.name.replace(/\s/g, '')+ name;
          this.getBase64fromFile(file.nativeFile).then((d:base64Response) =>{
          const  headers = new  HttpHeaders().set('Content-Type', 'multipart/form-data');
                  headers.set('Accept', 'application/json');
          // this.http.post(this.uploadUrl +name, d.body, { headers })
          //     .subscribe(
          //         (data: S3Response) => {
          //           if(c === 'icon') this.model.iconurl = data.Location;
          //           else this.model.backgroundurl = data.Location;
          //           this.toastr.success('File Uploaded');
          //         },
          //         error => { console.log('Error  in uoloaing file: '+error);}
          //     );

           });
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          if(c === 'icon') this.iconFile = output.file;
          else this.backroundFile = output.file;
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        // console.log(output.file.response.Location);
        // if(c === 'icon') {this.model.iconurl = output.file.response.Location; }
        // else { this.model.backgroundurl = output.file.response.Location; }
        // // The file is downloaded
        // ////////////////////////////////////////////////////////////////////////
        // if((this.model.files === null || this.model.files.length === 0) ||
        // this.model.files.find(f => f.upload_type == (c=== 'icon' ? 0:1)) === undefined){
        //   this.model.files.push({ id: null, created_at: new Date(), uploadable_type:'Project', uploadable_id : this.model.id,
        // upload_type: (c=== 'icon' ? 0:1),url: output.file.response.Location, is_deleted: false, updated_at: new Date() });
        // }//end if new
        // else{
        //   this.model.files.find(f => f.upload_type == (c=== 'icon' ? 0:1)).url = output.file.response.Location;
        // }
        break;
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: this.uploadUrl,
      method: 'POST',
      data: { name: 'bar.jpeg' }
    };

    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

   getBase64fromFile (file)  {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log(`getBase64fromFile success.`);
        const spliced = reader.result.split(',');
        const header = spliced[0];
        spliced.shift();
        resolve({
          header: header,
          body: spliced.join('')
        });
      };
      reader.onerror = (err) => {
        console.log(`getBase64fromFile failed.`);
        reject(err);
      };
    });
  }
  back(){
    this.router.navigate(['users', this.model.id]);

  }
  postForm() {

  } // end post form

}


