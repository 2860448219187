import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import  { API } from 'aws-amplify';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  constructor(public loader: LoadingBarService) { }
  public getAgents(): Promise<any> {
    const apiName = 'api';

    const path = `agents`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then(response => {
          this.loader.complete();
          const data = response.data;
          resolve(data.data);
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });
    }); // end promoise
    return promise;
  } // end getresult

  public saveAgent(model: User) {
    const apiName = 'api';
    const path = 'agents';
    const myInit = {
      body: model,
      headers: {}
    };
    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {
          const data = response;
          resolve(data);
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
}
