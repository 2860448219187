import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Amplify, { Auth } from 'aws-amplify';
import aws_exports from './aws-exports';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


const oauthConfig = {
  domain : 'frontendportal.auth.us-east-2.amazoncognito.com',
  scope : ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn : 'https://localhost:4200',
  redirectSignOut : 'https://localhost:4200',
  responseType: 'code',
  options: {
      // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
      AdvancedSecurityDataCollectionFlag : false
  }
};
Auth.configure({ oath: oauthConfig});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
