import { TagService } from './../services/tag.service';
import { Toastr, TOASTR_TOKEN } from './../services/toastr.service';
import { BreadCrumb } from './../model/BreadCrumb';
import { Component, OnInit, ViewChild, EventEmitter, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Task, Sprint, Question, Form } from '../model/sprint';
import { Page } from '../model/page';
import { ProjectService } from '../services/projectService';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { error } from 'util';
import { Tag } from '../model/tag';

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.css']
})
export class TaskFormComponent implements OnInit {
  formData: FormData;
  humanizeBytes: Function;
  dragOver: boolean;
  submitted = false;
  Sprint: Sprint = null;
  loading = true;
  email = null;
  id:number = null;
  projectId: number = null;
  model: Form = null;
  Questions: Question[] = null;
  Question : Question;
  crumbs: BreadCrumb[];
  tags: Tag[];
  constructor(private projectService: ProjectService, private activatedRoute: ActivatedRoute,
    private router: Router, private  toastr: ToastrService, private tagService: TagService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.projectId = +this.activatedRoute.snapshot.paramMap.get('id');
      this.id = +this.activatedRoute.snapshot.paramMap.get('form');
      console.log(this.projectId, this.id);
      this.GetSprint();
      this.GetTags();
    });
  }//end on init


  private GetSprint(id? : number){
    if(id) this.model.id  = id;
    if(id) this.id  = id;
    if(id)console.log('The model id is:: '+ this.model.id + ' and the id is: '+id);

    this.projectService.getSprint(this.email, this.projectId).then(sprint => {
      this.Sprint = sprint;
      if (this.id > 0) {
        this.model = this.Sprint.forms.find(t => t.id === this.id);
        this.Questions = this.model.questions.sort((q1, q2) => {
          if (q1.position > q2.position) {
            return 1;
          }
          if (q1.position < q2.position) {
            return -1;
          }
          return 0;
        });
        this.model.questions = this.Questions;
      } //end id is greater than 0
      else {
        this.model = {
        id: null, title: '', project_id: this.Sprint.id, created_at: new Date(),
          updated_at: new Date(), complete_duration: 10, titlear: ''
        };
      }
      this.buildBreadCrumb();
      this.loading = false;
    });
  }
  private GetTags(){
    this.tagService.getTags().then(tags => { this.tags = tags;});
  }
  onSubmit() { this.submitted = true; }


  getClass(id){
    return this.QuestionTypes.find(qt=> qt.id ===  id).class;
  }

  buildBreadCrumb(){
    //Sprints? Sprint()>Task Forms ()
    this.crumbs = [];
      this.crumbs.push(new BreadCrumb('Home','/'));

      this.crumbs.push(new BreadCrumb('Sprints','/sprints'));
      this.crumbs.push(new BreadCrumb(this.Sprint.name,'/sprints/'+this.Sprint.id));
      this.crumbs.push(new BreadCrumb(this.model.title ? this.model.title: 'Task Form' ,''));

      }

  addQuestion()
  {
        let p = 1;
        if(this.model.questions === null || this.model.questions === undefined) this.model.questions = [];
        if(this.model.questions.length >0) p = this.model.questions.length+1;
       // console.log(JSON.stringify(this.model.opts));
       this.Question=
          { task_form_id : this.model.id, question_type:1, is_visible_client: true, max_answer_points: 0,
            constraints: null, description:'', id:0 , opts :null, label:  null, labelar : null, position : p, answer_points: 0,
            is_required : true, is_deleted : false, is_random : false, created_at : new Date(), updated_at:new Date()};

  }

  postForm(){
    this.projectService.saveForm(this.model)
    .then((data) =>{
      //this.activatedRoute.snapshot.paramMap('form')
      this.model.id = data['id'];
     console.log('Saved..'+ data);
      this.toastr.success('Saved Successfully!');
      if(+this.activatedRoute.snapshot.paramMap.get('form') === 0){
        const queryParams: Params = { form: this.model.id };
        this.router.navigateByUrl('/sprints/'+this.model.project_id+'/taskform/'+this.model.id);
        // this.router.navigate(
        //   [],
        //   {
        //     relativeTo: this.activatedRoute,
        //     queryParams: queryParams,
        //     queryParamsHandling: 'merge', // remove to replace all query params by provided
        //   });
      }
    //  this.back();
    })
    .catch(err=> {
      this.toastr.error('Error in saving form');
      console.error(err);
    } );
   //this.back();
  }//end post form

  back(){
    this.router.navigate(['sprints', this.Sprint.id]);

  }
  questionSaved(question){
    //this.toastr.info(JSON.stringify(question));
    ;
    if(this.model.questions === undefined || this.model.questions === null) this.model.questions = [];
    if(question.id != null && question.id !== 0) this.model.questions.splice(this.model.questions.findIndex(q=>q.id=== question.id), 1, question);
    else this.model.questions.push(question);
    console.log('The question has been added:' +JSON.stringify(question));
    this.GetSprint(this.model.id);

  }

  listSorted(list: any){
    this.model.questions = [];
    for(let x=0;x<list.length; x++){
      list[x].position = x + 1;
      this.model.questions.push(list[x]);
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  QuestionTypes= [
    { 'id': 0, 'name':'MultiChoice', 'namear':'MultiChoice', 'class':'far fa-check-square'},
{ 'id': 1, 'name':'DropDownList', 'namear':'DropDownList', 'class':'fas fa-caret-square-down'},
{ 'id': 2, 'name':'Number', 'namear':'Number', 'class':'fas fa-keyboard'},
{ 'id': 3, 'name':'Paragraph', 'namear':'Paragraph', 'class':'fas fa-paragraph'},
{ 'id': 4, 'name':'SingleSelection', 'namear':'SingleSelection', 'class':'fas fa-caret-square-down'},
{ 'id': 5, 'name':'Text', 'namear':'Text', 'class':'fas fa-ad'},
{ 'id': 6, 'name':'ImageUpload', 'namear':'ImageUpload', 'class':'fas fa-image'},
{ 'id': 7, 'name':'MultipleImageUpload', 'namear':'MultipleImageUpload', 'class':'fas fa-images'},
{ 'id': 8, 'name':'SingleImageCapture', 'namear':'Image Capture', 'class':'far fas fa-images'},
{ 'id': 9, 'name':'MultipleImageCapture', 'namear':'MultipleImageCapture', 'class':'fas fa-images'},
{ 'id': 10, 'name':'LocationStamp', 'namear':'LocationStamp', 'class':'fas fa-map-marker'},
{ 'id': 11, 'name':'LocationMap', 'namear':'LocationMap', 'class':'fas fa-map'},
{ 'id': 12, 'name':'VoiceRecording', 'namear':'VoiceRecording', 'class':'fas fa-microphone'},
//{ 'id': 13, 'name':'MultiChoice', 'namear':'MultiChoice', 'class':'far fa-check-square'},
{ 'id': 14, 'name':'Phone', 'namear':'Phone', 'class':'fas fa-phone'},
{ 'id': 15, 'name':'Date', 'namear':'Date', 'class':'far fa-calendar'},
{ 'id': 16, 'name':'DateTime', 'namear':'DateTime', 'class':'fas fa-clock'},
{ 'id': 17, 'name':'Range', 'namear':'Range', 'class':'fas fa-exchange-alt'},
{ 'id': 19, 'name':'TagList', 'namear':'TagList', 'class':'fas fa-tags'},
{ 'id': 21, 'name':'SelectionWithText', 'namear':'SelectionWithText', 'class':'fas fa-clipboard-list'},
{ 'id': 23, 'name':'Infomation', 'namear':'Infomation', 'class':'fas fa-info'},
{ 'id': 24, 'name':'Video', 'namear':'Video', 'class':'fas fa-video'},
{ 'id': 25, 'name':'BarCode', 'namear':'BarCode', 'class':'fas fa-barcode'},
{ 'id': 26, 'name':'Dynamic Lookup', 'namear':'Dynamic Lookup', 'class':'fas fa-search-plus'},
{ 'id': 27, 'name':'POI Filter', 'namear':'POI Filter', 'class':'fas fa-search-plus'}


    ];
}
